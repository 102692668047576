import { RightIcon } from '../data/svgIcons/all-svg-icons'
import { useContext, useRef } from 'react'
import { LanguageContext } from '../hook/context'
import Link from 'next/link'
import confetti from 'canvas-confetti'

const GetStartButton = () => {
  const { t } = useContext(LanguageContext)
  const buttonRef = useRef(null)

  const handleConfetti = () => {
    const { clientWidth, clientHeight } = document.documentElement
    const boundingBox = buttonRef.current?.getBoundingClientRect?.()

    const targetY = boundingBox?.y ?? 0
    const targetX = boundingBox?.x ?? 0
    const targetWidth = boundingBox?.width ?? 0

    const targetCenterX = targetX + targetWidth / 2

    confetti({
      zIndex: 999,
      particleCount: Math.floor(200 + Math.random() * 100),
      spread: 100,
      origin: {
        y: targetY / clientHeight + 0.03,
        x: targetCenterX / clientWidth,
      },
    })
  }

  return (
    <Link href='mailto:info@docode.com.tr'>
      <button
        ref={buttonRef}
        onClick={handleConfetti}
        type='button'
        className='text-white text-base md:text-lg md:px-6 md:py-3.5 px-5 py-3 relative rainbowButton font-bold justify-center rounded-lg   text-center inline-flex items-center mr-2 my-8'
      >
        <div className='absolute rainbowButton w-full h-full left-0 rounded-lg -z-10 blur-xl'></div>
        <div className='absolute bg-white/10 -inset-1 rounded-xl -z-10 '></div>
        {t.header.button}
        <RightIcon />
      </button>
    </Link>
  )
}

export default GetStartButton
